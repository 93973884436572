import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Ласкаво просимо до Visionary Pathways
			</title>
			<meta name={"description"} content={"Створіть майбутнє свого бренду вже сьогодні"} />
			<meta property={"og:title"} content={"Vítejte ve Visionary Pathways"} />
			<meta property={"og:description"} content={"Ласкаво просимо до Visionary Pathways"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-1.jpg?v=2024-04-04T13:02:43.055Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header2>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-15">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 0px 16px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				justify-content="space-between"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
						md-text-align="left"
					>
						Про нас
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						У самому серці цифрового світу, що стрімко розвивається, Visionary Pathways народилася з єдиної ідеї: змінити спосіб комунікації брендів зі своєю аудиторією. Наш шлях почався з пристрасті до сторітелінгу, почуття творчості та місії створювати незабутні зв'язки між брендами та їхніми спільнотами.{"\n"}
						<br />
						{"\n"}З роками ми перетворилися з невеликої команди мрійників на повноцінне агентство, яке визначає майбутнє маркетингу за допомогою інноваційних стратегій та передових технологій. Наш шлях сформувався завдяки успіхам брендів, з якими ми працювали, і кожна історія зробила свій внесок у нашу спадщину креативності та впливу.
					</Text>
					<Link
						href="/about"
						padding="12px 24px 12px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="2rem 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-primary"
						hover-transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-primary"
						text-align="center"
					>
						Дізнатися більше
					</Link>
				</Box>
				<Box width="100%" display="flex" sm-flex-wrap="wrap">
					<Box
						display="flex"
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						flex-direction="column"
						sm-width="50%"
					>
						<Box
							overflow-x="hidden"
							overflow-y="hidden"
							position="relative"
							transform="translateY(0px)"
							hover-transform="translateY(-10px)"
							width="100%"
							height="auto"
							transition="transform 0.2s ease-in-out 0s"
							padding="0px 0px 125% 0px"
						>
							<Image
								width="100%"
								left={0}
								src="https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-1.jpg?v=2024-04-04T13:02:43.062Z"
								object-fit="cover"
								position="absolute"
								display="block"
								top="auto"
								right={0}
								bottom="0px"
								min-height="100%"
								srcSet="https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-1.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-1.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-1.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-1.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-1.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-1.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-1.jpg?v=2024-04-04T13%3A02%3A43.062Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
					<Box
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						flex-direction="column"
						sm-width="50%"
						display="flex"
					>
						<Box
							width="100%"
							height="auto"
							position="relative"
							padding="0px 0px 125% 0px"
							overflow-x="hidden"
							overflow-y="hidden"
							transform="translateY(0px)"
							transition="transform 0.2s ease-in-out 0s"
							hover-transform="translateY(-10px)"
						>
							<Image
								display="block"
								width="100%"
								top="auto"
								right={0}
								position="absolute"
								object-fit="cover"
								left={0}
								bottom="0px"
								min-height="100%"
								src="https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/1-5.jpg?v=2024-04-04T13:02:43.059Z"
								srcSet="https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/1-5.jpg?v=2024-04-04T13%3A02%3A43.059Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/1-5.jpg?v=2024-04-04T13%3A02%3A43.059Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/1-5.jpg?v=2024-04-04T13%3A02%3A43.059Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/1-5.jpg?v=2024-04-04T13%3A02%3A43.059Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/1-5.jpg?v=2024-04-04T13%3A02%3A43.059Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/1-5.jpg?v=2024-04-04T13%3A02%3A43.059Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/1-5.jpg?v=2024-04-04T13%3A02%3A43.059Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
					<Box
						flex-direction="column"
						sm-width="50%"
						display="flex"
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
					>
						<Box
							overflow-x="hidden"
							overflow-y="hidden"
							position="relative"
							transition="transform 0.2s ease-in-out 0s"
							hover-transform="translateY(-10px)"
							height="auto"
							transform="translateY(0px)"
							padding="0px 0px 125% 0px"
							width="100%"
						>
							<Image
								object-fit="cover"
								width="100%"
								right={0}
								bottom="0px"
								min-height="100%"
								src="https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-4%20%281%29.jpg?v=2024-04-04T13:02:43.051Z"
								position="absolute"
								display="block"
								top="auto"
								left={0}
								srcSet="https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-4%20%281%29.jpg?v=2024-04-04T13%3A02%3A43.051Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-4%20%281%29.jpg?v=2024-04-04T13%3A02%3A43.051Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-4%20%281%29.jpg?v=2024-04-04T13%3A02%3A43.051Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-4%20%281%29.jpg?v=2024-04-04T13%3A02%3A43.051Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-4%20%281%29.jpg?v=2024-04-04T13%3A02%3A43.051Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-4%20%281%29.jpg?v=2024-04-04T13%3A02%3A43.051Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/3-4%20%281%29.jpg?v=2024-04-04T13%3A02%3A43.051Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
					<Box
						display="flex"
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						flex-direction="column"
						sm-width="50%"
					>
						<Box
							position="relative"
							transition="transform 0.2s ease-in-out 0s"
							width="100%"
							height="auto"
							overflow-y="hidden"
							padding="0px 0px 125% 0px"
							overflow-x="hidden"
							transform="translateY(0px)"
							hover-transform="translateY(-10px)"
						>
							<Image
								object-fit="cover"
								position="absolute"
								width="100%"
								left={0}
								bottom="0px"
								src="https://uploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-4.jpg?v=2024-04-04T13:02:43.050Z"
								display="block"
								top="auto"
								right={0}
								min-height="100%"
								srcSet="https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-4.jpg?v=2024-04-04T13%3A02%3A43.050Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-4.jpg?v=2024-04-04T13%3A02%3A43.050Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-4.jpg?v=2024-04-04T13%3A02%3A43.050Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-4.jpg?v=2024-04-04T13%3A02%3A43.050Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-4.jpg?v=2024-04-04T13%3A02%3A43.050Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-4.jpg?v=2024-04-04T13%3A02%3A43.050Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/660e75ec3f24780021f5a4dd/images/2-4.jpg?v=2024-04-04T13%3A02%3A43.050Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					padding="0px 0px 90% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image
						src="https://images.unsplash.com/photo-1533749871411-5e21e14bcc7d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						object-fit="cover"
						left={0}
						right={0}
						min-height="auto"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						height="100%"
						srcSet="https://images.unsplash.com/photo-1533749871411-5e21e14bcc7d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1533749871411-5e21e14bcc7d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1533749871411-5e21e14bcc7d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1533749871411-5e21e14bcc7d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1533749871411-5e21e14bcc7d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1533749871411-5e21e14bcc7d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1533749871411-5e21e14bcc7d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--light"
					font="--base"
					md-margin="0px 0px 20px 0px"
					opacity="1"
				>
					У Visionary Pathways ми віримо в силу різноманітності, яка стимулює творчість. Наша команда - це мозаїка талантів, кожен член якої привносить свої унікальні навички та погляди. Від досвідчених стратегів до цифрових майстрів, від творців контенту до майстрів дизайну - наш колективний досвід дозволяє нам вирішувати будь-які проблеми та перетворювати їх на можливості. Саме ця синергія дозволяє нам створювати індивідуальні рішення, які не лише охоплюють аудиторію, але й приносять відчутні результати.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Наш творчий арсенал
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--lead">
					Інноваційний підхід: ми не просто послідовники трендів - ми творці і постійно шукаємо нові способи залучення та захоплення.
					<br />
					<br />
					Індивідуальні рішення.

Перевірений досвід: портфоліо успішних кампаній свідчить про те, що наша робота сприяє зростанню та залученню брендів.
					<br />
					<br />
					Готовність до майбутнього: ми допоможемо вашому бренду орієнтуватися в цифровому ландшафті завтрашнього дня вже сьогодні.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						FAQ
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Чим Visionary Pathways відрізняється від інших маркетингових агентств?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Наша унікальна комбінація креативної винахідливості, стратегічного планування та прагнення випереджати галузеві тренди виділяє нас серед інших. Ми адаптуємо наші послуги до конкретних потреб кожного бренду, забезпечуючи персоналізований підхід, який приносить вражаючі результати.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Чи може Visionary Pathways допомогти з цифровим та традиційним маркетингом?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Абсолютно! Ми пропонуємо комплексний набір послуг, який охоплює як цифрові, так і традиційні маркетингові канали. Від SEO та соціальних медіа до друкованої реклами та подієвого маркетингу, у нас є експертиза, щоб підвищити присутність вашого бренду на всіх платформах.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Як ви вимірюєте успішність своїх маркетингових кампаній?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Метрики успіху адаптовані до кожної кампанії та включають різні KPI, такі як рівень залученості, трафік вебсайту, рівень конверсії та рентабельність інвестицій. Ми віримо в прозорість і надаємо регулярні, детальні звіти, щоб ви були поінформовані про ефективність вашої кампанії.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Який процес початку проекту з Visionary Pathways?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Процес починається з консультації, щоб зрозуміти вашу візію, цілі та виклики. Потім ми створюємо індивідуальну пропозицію, яка описує наш стратегічний підхід. Після затвердження ми переходимо до створення та реалізації вашої маркетингової стратегії.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Чи пропонуєте ви пакети послуг, чи все індивідуально?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Хоча ми пропонуємо ряд послуг, наш підхід полягає в тому, щоб адаптувати наші пропозиції до унікальних потреб і цілей вашого бренду. Ми віримо, що індивідуальна стратегія є ключем до досягнення найкращих результатів.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							На які галузі ви спеціалізуєтесь?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Ми маємо досвід у різних галузях і готові прийняти виклики в будь-якій галузі. Широкі знання нашої команди гарантують, що ми можемо створювати ефективні стратегії незалежно від вашої галузі.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Скільки часу потрібно, щоб побачити результати з маркетингової кампанії?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Терміни отримання результатів можуть варіюватися залежно від обсягу та характеру кампанії. Ми зосереджені на створенні стійкого зростання, що іноді означає, що результати будуть нарощуватися з часом, а не з'являться відразу.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text3" />
			<Override slot="text2" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text6" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});